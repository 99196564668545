import { Button } from "@/components";
import styled from "@emotion/styled";
import { map } from "lodash";
import React, { useRef, useState } from "react";

const BaseButton = Button.BaseButton.withComponent("button");

const Form = styled.form`
  input,
  select,
  textarea {
    border-radius: 3px;
  }
`;

const Control = ({ children }) => (
  <div className="uk-margin">
    <div className="uk-form-controls">{children}</div>
  </div>
);

const Upload = () => {
  const upload = useRef(null);
  const [files, setFiles] = useState([]);

  const onChange = () => {
    setFiles(upload.current.files);
  };

  return (
    <div>
      <p>Attach files</p>
      <p>
        <i>
          Attach any files you believe are relevant to your application. E.g.
          Cover letter, resume, portfolio, website. Individual files must not
          exceed 5MB.
        </i>
      </p>

      <div className="uk-grid" data-uk-grid>
        <div className="js-upload" data-uk-form-custom>
          <input
            name="files"
            ref={upload}
            type="file"
            multiple
            onChange={onChange}
          />
          <BaseButton
            color="yellow"
            className="uk-button uk-button-small uk-border-rounded"
            type="button"
          >
            Choose files
          </BaseButton>
        </div>

        <ul className="uk-list">
          {map(files, file => (
            <li key={file.name}>{file.name}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const JoinUs = () => (
  <div className="uk-section uk-section-large uk-height-viewport">
    <div className="uk-container uk-container-small">
      <div className="uk-h3">Send in your application</div>

      <Form
        className="uk-form-width-large"
        name="application"
        method="POST"
        action="/success"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        enctype="multipart/form-data"
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="application" />

        <div hidden>
          <label htmlFor="bot-field">
            Don’t fill this out if you're human: <input name="bot-field" />
          </label>
        </div>

        <Control>
          <input
            name="name"
            className="uk-input"
            type="text"
            placeholder="Your Name*"
            required
          />
        </Control>
        <Control>
          <input
            name="email"
            className="uk-input"
            type="email"
            placeholder="Email*"
            required
          />
        </Control>
        <Control>
          <select name="position" className="uk-select" defaultValue="">
            <option value="" disabled hidden>
              Position*
            </option>
            <option value="Business">Business</option>
            <option value="Design">Design</option>
            <option value="Technology">Technology</option>
          </select>
        </Control>
        <Control>
          <textarea
            name="message"
            className="uk-textarea"
            rows="6"
            maxLength="2000"
            placeholder="Message"
          />
        </Control>

        <Control>
          <Upload />
        </Control>

        <div className="uk-margin uk-margin-large-top">
          <div className="uk-form-controls uk-text-right">
            <BaseButton
              color="yellow"
              type="submit"
              className="uk-button uk-button-small uk-link-reset uk-border-rounded"
            >
              Submit
            </BaseButton>
          </div>
        </div>
      </Form>
    </div>
  </div>
);

export default JoinUs;
